.journey__image {
    padding: 2rem;
    width: 100%;
}

.MuiGrid-item .MuiTextField-root {
    width: 100%;
}

.step__button-group {
    z-index: 1;
    position: sticky;
    bottom: 0;
    left: 0;
}
.account-holder_same {
    .MuiCheckbox-colorPrimary {
        color: white !important
    }
    &.disabled {
        opacity: .5
    }
}
.client_step-tab {
    position: relative;
    z-index: 1;
    &.active, &-error.active {
        color: $base-color !important;
    }
    &-error::before {
        z-index: 2;
        width: 15px;
        height: 15px;
        font-size: 13px;
        content: '!';
        background-color: $warning;
        border-radius: 50%;
        color: white;
        position: absolute;
        top: 5px;
        right: 0;
    }
}
.css-1aquho2-MuiTabs-indicator {
    background-color: $base-color !important;
}