.base__logo {
        max-width: 200px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.7);
    z-index: 10;
    opacity: 0;
    transition: all .25s ease-in-out;
    &.show {
        opacity: 10;
    }
}
.layout {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // min-height: calc(100vh - 100px);
    min-height: 100vh;
}
.primary-text {
    color: $primary-color;
}
.secondary-text {
    color: $secondary-text
}
.active-claim-number {
    padding: 2px 5px;
    border-radius: 5px;
    color: #EAB000;
    background-color: $primary-text
}
.contact-link {
    color: $primary-color;
}

.user-select-none {
    user-select: none;
}
.common__page-title {
    text-align: center;
}
.mdi-icon {
    color: $base-color;
    margin: .5rem;
}
.cursor-pointer {
    cursor: pointer;
}

img {
    max-width: 100%;
}